@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "bootstrap/dist/css/bootstrap.css";
@import "./variables.scss";

.App {
    text-align: center;
    // @include flexCenter(column);
    font-family: "EB Garamond", serif;
}

.content {
    max-width: 2100px;
    margin: auto;
    padding: 0 70px;
    width: 100%;
}
.clear {
    height: 100px;
    width: 100%;
}

@media only screen and (max-width: 1076px) {
    .content {
        padding: 0px !important;
    }

    h1 {
        font-size: 35px !important;
    }
    .clear {
        height: 75.5px !important;
    }
}
