@import "../variables.scss";
.nav-background {
    width: 100%;
    background-color: white !important;
    height: 100px;
    position: fixed;
}
.Navbar {
    background-color: white;
    height: 100px;
    padding: 0 70px;
    max-width: 2100px;
    margin: auto;
}
.border-bottom-black {
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.164);
    animation: fadeIn 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

.nav-link {
    color: black !important;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 20px;
}
.Navbar-brand {
    height: 100%;
    width: 250px;
}
.nav-logo {
    width: 100px;
}
.mobile-logo {
    display: none !important;
}
.call-button {
    // padding: 10px 20px !important;
    color: white !important;
    background-color: $green;
    font-weight: 400;
    text-align: center;
    border-radius: 10px;
    width: 150px;
    margin-left: 10px;
    img {
        padding-left: 7px;
    }
}

.navbar-collapse {
    // height: 251px !important;
}

@media only screen and (max-width: 1076px) {
    .Navbar {
        height: auto !important;
        padding: 10px 25px;
    }
    .call-button {
        margin: auto;
    }
    .Navbar-brand {
        height: 100%;
        width: 200px;
    }
    .nav-logo {
        width: 65px;
    }
    .mobile-logo {
        display: block !important;
    }
    .desktop-logo {
        display: none !important;
    }
    .nav-background {
        height: 70px !important;
    }
    .border-bottom-black {
        box-shadow: 0px 7px 0px 0px rgba(0, 0, 0, 0.164);
        animation: fadeIn 2s;
    }
}
