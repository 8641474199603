@import "../variables.scss";
.carousel-div {
    height: calc(100vh - 100px);
    animation: fadeIn 1s;
    background-repeat: no-repeat;
    max-width: 2100px !important;
    margin: auto;
}
.carousel-img {
    height: calc(100vh - 100px);
    background-repeat: no-repeat;
}
.img1 {
    background-image: url("../assets/backgroundImage1.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.img2 {
    background-image: url("../assets/backgroundImage2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.img3 {
    background-image: url("../assets/backgroundImage3.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.img4 {
    background-image: url("../assets/backgroundImage4.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.img5 {
    background-image: url("../assets/backgroundImage5.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.hero-container {
    max-width: 2100px;
    @include flexCenter(column);
    justify-content: flex-start !important;
    align-items: flex-start !important;
    margin: auto;
}
.hero {
    // min-height: calc(100vh - 100px);
    position: absolute;
    top: 0;
    max-width: 2100px;
    margin: auto;
    background-repeat: no-repeat;
    // transition: background-image 0.5s linear;
    // animation: fadeIn 1s;
    // min-height: calc(100vh - 150px);
    // transform: scaleX(-1);
    height: 100%;
    @include flexCenter(column);
    align-items: flex-start;
    padding-left: 70px;
    // backface-visibility: hidden;
    // transform: translate3d(0, 0, 0);
    // -webkit-backface-visibility: hidden;
    // -moz-backface-visibility: hidden;
    // -ms-backface-visibility: hidden;
    // .hero {
    //     background-repeat: no-repeat;
    //     background-position: center;
    //     background-size: cover;
    //     min-height: calc(100vh - 100px);
    //     max-width: 1800px;
    //     margin: auto;
    //     // transition: background-image 0.5s linear;
    //     animation: fadeIn 1s;
    //     // min-height: calc(100vh - 150px);
    //     // transform: scaleX(-1);
    //     height: 100%;
    //     @include flexCenter(column);
    //     align-items: flex-start;
    //     padding-left: 70px;
    //     // backface-visibility: hidden;
    //     // transform: translate3d(0, 0, 0);
    //     // -webkit-backface-visibility: hidden;
    //     // -moz-backface-visibility: hidden;
    //     // -ms-backface-visibility: hidden;
    @keyframes fadeIn {
        0% {
            opacity: 0.3;
        }
        100% {
            opacity: 1;
        }
    }
    h1 {
        font-weight: 300;
        font-size: 50px;
    }
    p {
        font-size: 20px;
        font-weight: 300;
        max-width: 491px;
        margin: 0;
        padding: 0;
        text-align: left;
        font-family: "Roboto", sans-serif;
    }
    .products-button {
        margin-top: 50px;
        padding: 8px 25px;
        background-color: $green;
        color: white !important;
        border-radius: 15px;
        font-size: 20px;
        border: none;
        font-family: "Roboto", sans-serif;
        font-weight: 400;

        img {
            padding-left: 10px;
        }
    }

    .fairtrade {
        width: 223px;
        margin-top: 50px;
    }
}

// @media only screen and (max-width: 892px) {
//     .hero {
//         height: calc(100vh - 75.5px);
//         align-items: center;
//         justify-content: flex-start;
//         padding: 25px 20px !important;
//         align-items: center;
//         margin-top: 75.5px;
//     }
// }
@media only screen and (max-width: 1076px) {
    .hero {
        height: calc(100vh - 75.5px);
        align-items: center;
        justify-content: flex-start;
        padding: 25px 20px !important;
        align-items: center;
        margin-top: 75.5px;
        p {
            text-align: center !important;
        }
        h1 {
            text-align: left;
            margin-top: 5px !important;
            font-size: 35px !important;
            margin-bottom: 25px !important;
            font-weight: 500 !important;
        }
        .fairtrade {
            width: 170px !important;
            margin-top: 25px !important;
        }
        .products-button {
            margin-top: 25px !important;
        }
    }
    .hero-container {
        justify-content: center !important;
        align-items: center !important;
    }
    .carousel-div {
        height: calc(100vh - 75.5px) !important;
        padding: 0 !important;
    }

    .img1 {
        background-image: url("../assets/mobileBackground1.jpg");
    }
    .img2 {
        background-image: url("../assets/mobileBackground2.jpg");
    }
    .img3 {
        background-image: url("../assets/mobileBackground3.jpg");
    }
    .img4 {
        background-image: url("../assets/mobileBackground4.jpg");
    }
    .img5 {
        background-image: url("../assets/mobileBackground5.jpg");
    }
    .carousel-img {
        height: calc(100vh - 75.7px);
    }
}

@media only screen and (max-width: 366px) {
    .hero {
        & h1 {
            font-size: 30px !important;
        }
    }
}
