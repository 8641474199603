@import "../variables.scss";

.what-we-do-container {
    @include flexCenter(column);
    height: 827px;
    width: 100%;
    .what-we-do-title {
        @include flexCenter(row);
        position: relative;
        max-width: 600px;
        margin: auto;
        .left-leaves {
            width: 170px;
            position: absolute;
            left: -15px;
            top: -100px;
        }
        .right-leaves {
            width: 170px;
            position: absolute;
            right: -15px;
            top: -100px;
        }
    }
    & h1 {
        margin-bottom: 30px;
        font-size: 42px;
        font-weight: 500;
    }
    & h4 {
        margin-bottom: 30px;
        font-size: 28px;
        font-weight: 400;
        // max-width: 180px;
        margin: auto;
    }

    .blocks {
        max-width: 1076px;
        height: 100%;
        @include flexCenter(row);
        align-items: flex-start;
        // flex-wrap: wrap;
        .block {
            min-width: 278px;
            margin: 0 30px;
            text-align: center;
            max-width: 800px;

            p {
                text-align: justify;
                @include roboto(20px);
                font-weight: 300;
                margin-top: 20px;
            }
        }
    }
}

@media only screen and (max-width: 1433px) {
    .what-we-do-container {
        justify-content: flex-start !important;
        padding-top: 120px;
        height: 100%;
        & h1 {
            margin: 0 !important;
            margin-bottom: 20px !important;
        }
        .left-leaves {
            // display: block;
            // position: relative !important;
            top: -65px !important;
            left: 10px !important;
            width: 120px;
        }
        .right-leaves {
            // display: none;
            top: -65px !important;
            right: 10px !important;
            width: 120px;
        }
    }
    .blocks {
        display: block;
        flex: 1;
        // margin: 0 25px !important;
    }

    .block {
        text-align: justify;
    }
}

@media only screen and (max-width: 1076px) {
    .what-we-do-container {
        padding-top: 30px !important;
    }
    .left-leaves {
        // display: block;
        // position: relative !important;
        top: -65px !important;
        left: 10px !important;
        width: 120px;
        display: none !important;
    }
    .right-leaves {
        // display: none;
        top: -65px !important;
        right: 10px !important;
        width: 120px;
        display: none !important;
    }
}
