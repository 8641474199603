@import "../variables.scss";

.delivery-and-services-container {
    @include flexCenter(column);
    max-width: 2100px;
    margin: auto;
    height: 100%;
    width: 100%;
    padding-bottom: 200px;
    position: relative;

    .mixed-fruits-left {
        position: absolute;
        bottom: 40px;
        left: 50px;
    }
    .mixed-fruits-right {
        position: absolute;
        bottom: -10px;
        right: 100px;
    }

    .delivery-and-services-title {
        max-width: 600px;
        margin-top: 140px !important;
        margin: auto;
        position: relative;
        .left-leaves {
            width: 170px;
            position: absolute;
            left: -15px;
            top: -100px;
        }
        .right-leaves {
            width: 170px;
            position: absolute;
            right: -15px;
            top: -100px;
        }
    }
    & h1 {
        margin-bottom: 30px;
        margin-top: 60px;
        font-size: 42px;
        font-weight: 500;
    }
    & p {
        margin-top: 20px !important;
    }
    & h4 {
        margin-bottom: 30px;
        font-size: 28px;
        font-weight: 400;
        // max-width: 180px;
        margin: auto;
    }

    .blocks {
        max-width: 1249px;
        height: 100%;
        @include flexCenter(row);
        align-items: flex-start;
        // flex-wrap: wrap;
        .block {
            min-width: 300px;
            margin: 0 30px;
            text-align: center;
            flex: 1;
            max-width: 800px !important;

            p {
                text-align: justify;
                @include roboto(20px);
                font-weight: 300;
                margin-top: 30px;
            }
        }
    }
}

@media only screen and (max-width: 1076px) {
    .delivery-and-services-container {
        justify-content: flex-start !important;
        height: 100%;
        padding-bottom: 150px !important;
        .mixed-fruits-left {
            width: 270px;
            left: 50%;
            transform: translate(-50%, 50%);
            bottom: 70px;
        }
        .delivery-and-services-title {
            margin-top: 30px !important;
        }
        .mixed-fruits-right {
            width: 150px;
            bottom: 0px;
            right: 25px;
            display: none !important;
        }

        & h1 {
            margin: 0 !important;
            margin-bottom: 20px !important;
        }
        .blocks {
            display: block;
            flex: 1;
            // margin: 0 25px !important;
        }
        .clear {
            height: 75.5px !important;
        }

        .left-leaves {
            // display: block;
            // position: relative !important;
            top: -65px !important;
            left: 10px !important;
            width: 120px;
            display: none !important;
        }
        .right-leaves {
            // display: none;
            top: -65px !important;
            right: 10px !important;
            width: 120px;
            display: none !important;
        }
        .block {
            text-align: justify;
            min-width: 278px !important;
            max-width: 800px !important;
        }
    }
}
