@import "../variables.scss";
.contact-container {
    display: flex;
    @include flexCenter(column);
    margin: auto;
    padding: 70px 70px 0 70px;

    p {
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-weight: 300 !important;
    }
    h1 {
        font-family: "EB Garamond", serif;
    }
    .map-and-details-container {
        max-width: 2100px !important;
        @include flexCenter(row);
        text-align: left;
        margin-bottom: 100px;
        flex-wrap: wrap;
        .google-map-container {
            flex: 3;
            padding-right: 30px;
        }
        .map-details-container {
            flex: 2;
            min-width: 300px;
        }
    }
}
.contact-form-container {
    // border: 1px solid red;
    width: 100%;
    justify-content: center;
    margin: auto;
    background-color: #f6f6f8 !important;
    text-align: center !important;
    p {
        font-family: "Roboto", sans-serif;
    }
}

@media only screen and (max-width: 1076px) {
    .map-and-details-container {
        flex-direction: column !important;
    }
    .google-map-container {
        flex: 1 !important;
        margin: auto;
        padding: 0 !important;
        order: 1;
    }
    .map-details-container {
        flex: 1 !important;
    }
    .contact-container {
        padding: 30px 25px 0 !important;
    }
}

@media only screen and (max-width: 1319px) {
    .google-map-container {
        order: 1;
        @include flexCenter(row);
        padding-right: 0px !important;
    }
    .map-details-container {
        max-width: 900px;
        text-align: center !important;
    }
    .contact-container {
        padding-bottom: 0 !important;
    }
}
