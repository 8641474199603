@import "../variables.scss";

.products-container {
    max-width: 2100px;
    margin: auto;
    @include flexCenter(column);

    .products-header {
        max-width: 600px;
        margin-top: 140px;
        margin-bottom: 50px;
        position: relative;
        .left-leaves {
            width: 170px;
            position: absolute;
            left: -15px;
            top: -100px;
        }
        .right-leaves {
            width: 170px;
            position: absolute;
            right: -15px;
            top: -100px;
        }
        & h1 {
            margin-bottom: 30px !important;
        }
        & p {
            text-align: justify;
            @include roboto(20px);
            margin: 0;
            font-weight: 300;
        }
    }

    .products-block-container {
        padding: 0 146px;
        width: 100%;
        @include flexCenter(column);
        margin: auto;
        .last {
            margin-bottom: 100px !important;
        }
        .products-block {
            @include flexCenter(row);
            max-width: 1148px;
            height: 372px;
            margin: 25px 0;
            .restaurants {
                background-image: url("../assets/restaurants.png");
            }
            .schools {
                background-image: url("../assets/schools.png");
            }
            .cafes {
                background-image: url("../assets/cafes.png");
            }
            .vegan {
                background-image: url("../assets/vegan.png");
            }
            .festival {
                background-image: url("../assets/festival.png");
            }

            .products-image-block {
                background-repeat: no-repeat;
                background-size: cover;
                width: 574px;
                // min-height: 200px;
                height: 100%;
            }
            .products-text-block {
                padding: 0 53px;
                width: 574px;
                height: 100%;
                background-color: #f6f6f8;
                text-align: left;
                @include flexCenter(column);
                align-items: flex-start;
                & h3 {
                    margin-bottom: 15px;
                }
                & p {
                    text-align: justify;
                    @include roboto(20px);
                    font-weight: 300;
                    margin-bottom: 50px;
                }
                a {
                    font-size: 24px;
                    color: #fb825a;
                    font-weight: 600;
                    &::after {
                        content: url("../assets/arrow.svg");
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1076px) {
    .products-container {
        width: 100%;
        padding: 30px 25px 0 !important;
        .products-header {
            margin: 0px !important;
            h1 {
                font-size: 35px !important;
                margin-bottom: 20px !important;
            }
            .left-leaves {
                display: none;
                // position: relative !important;
                top: -70px !important;
                left: 10px !important;
                width: 120px;
            }
            .right-leaves {
                display: none;
                top: -70px !important;
                right: 10px !important;
                width: 120px;
            }
        }
    }

    .products-block-container {
        padding: 0 !important;
        width: 100% !important;

        max-width: 600px;
    }
    .products-block {
        flex-direction: column !important;

        height: 100% !important;
        width: 100% !important;
        padding: 0;
        .products-image-block {
            order: 0;
            max-width: 600px;
            width: 100% !important;
            min-height: 300px;
        }
        .products-text-block {
            order: 1;
            margin: auto;
            text-align: left;
            margin: 0 !important;
            max-width: 600px;
            width: 100% !important;
            padding: 0 20px !important;
            h3,
            p,
            a {
                margin: 10px 0 25px !important;
                padding: 0 !important;
            }
            p {
                text-align: left !important;
                margin-top: 0 !important;
                margin-bottom: 15px !important;
            }

            h3 {
                margin-top: 20px !important;
                margin-bottom: 15px !important;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .products-image-block {
        min-height: 230px !important;
    }
}
