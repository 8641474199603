@import "../variables.scss";

.footer-container {
    height: 330px;
    background-color: $darkgreen;
    color: white;
    text-align: left;
    @include flexCenter(column);

    .footer-content {
        @include flexCenter(row);
        justify-content: space-between;
    }

    & p {
        font-family: "Roboto", sans-serif;
        font-weight: 300;
        font-size: 20px;
        margin-bottom: 15px;
    }
    & a {
        color: white;
    }
    & h1 {
        font-size: 60px;
        font-weight: 400;
        margin-bottom: 30px;
    }
    .social-media {
        @include flexCenter(row);
        justify-content: flex-start;
        & :nth-child(2) {
            padding-left: 30px;
        }
    }
}

@media only screen and (max-width: 1076px) {
    .footer-container {
        padding: 0 25px !important;
        height: 330px !important;
        p {
            font-size: 20px;
            margin-bottom: 15px;
        }
        a {
        }
        h1 {
            font-size: 42px !important;
            margin-bottom: 20px !important;
            margin-top: 0px !important;
        }
    }
}

@media only screen and (max-width: 737px) {
    .footer-content-right {
        display: none !important;
    }
}
