@import "../variables.scss";
.contact-form-div {
    @include flexCenter(column);
    height: 100%;
    padding-bottom: 70px;
    justify-content: flex-start;
    padding-top: 50px;
    background-color: #f6f6f8;
    position: relative;
    max-width: 2100px !important;
    margin: auto;

    .confirmation-message {
        animation: fadeIn 2s;
        height: 738px;
        @include flexCenter(column);

        h2 {
            font-weight: 300 !important;
            margin: 20px 0;
            font-size: 30px !important;
        }
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .contact-form {
        z-index: 1;
    }
    h1 {
        font-weight: 400;
    }
    p {
        font-weight: 300;
    }
    .contact-form-contents {
        max-width: 470px;
        padding: 0 50px;
    }
    .contact-form-fields {
    }
    textarea,
    input {
        border: none;
        background-color: #f6f6f8;
        border-bottom: 1px solid rgba(0, 0, 0, 0.432);
        width: 100%;
    }
    #message-input {
        height: auto;
        width: 100%;
    }
    .recaptcha-div {
        @include flexCenter(column);
        margin-top: 20px;
    }
    .button-centered {
        text-align: center !important;
    }
    .submit-button {
        margin-top: 20px;
        width: 108px;
        height: 52px;
        font-size: 28px;
        font-weight: 400;
        color: white;
        background-color: #277d2a;
        border: none;
        padding: 0 1px;
        text-align: center !important;
        display: inline-block !important;
    }
    .terms {
        text-align: justify;
        width: 304px;
        margin: auto;
        margin-top: 20px;
        p {
            margin: 0 !important;
            font-size: 16px;
        }
    }

    .contact-form-fields {
        text-align: left;
        label {
            font-family: "Roboto", sans-serif;
            font-weight: 300;
            font-size: 20px;
            margin-top: 15px;
        }
        input {
            font-size: 20px;
            font-family: "Roboto", sans-serif;
            height: 40px;
            font-weight: 300;
        }
        textarea {
            font-size: 20px;
            font-weight: 300;
            font-family: "Roboto", sans-serif;
        }
    }
    .background-leaves {
        position: absolute;
        height: 770px;
        width: 100%;
    }
    .left-leaves-contact-form {
        position: absolute;
        left: 15%;
        bottom: 45%;
        z-index: 20;
    }
    .right-leaves-contact-form {
        position: absolute;
        right: 15%;
        bottom: 45%;
        z-index: 20;
    }
}

@media only screen and (max-width: 1076px) {
    .background-leaves {
        display: none !important;
    }
    .contact-form-contents {
        padding: 0 !important;
    }
    .contact-form-div {
        padding: 25px;
    }
    .confirmation-message {
        height: 749px !important;
    }
}
