$green: #277d2a;
$darkgreen: #164f39;
@mixin flexCenter($direction) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: $direction;
}
@mixin roboto($fontsize) {
    font-family: "Roboto", sans-serif;
    font-size: $fontsize;
}

// @include flexCenter(column);
