@import "../variables.scss";

.about-container {
    max-width: 2100px;
    margin: auto;
    @include flexCenter(column);
    padding: 0 70px;
    padding-top: 100px;

    .about-header {
        width: 100%;
        margin-top: 40px;
        margin-bottom: 50px;
        position: relative;
        @include flexCenter(column);
        flex-wrap: wrap;
        align-items: flex-start;
        max-width: 1148px;

        .about-us {
            text-align: justify;
            max-width: 1000px !important;
            margin: auto;
            margin-bottom: 50px;
            position: relative;
            .about-us-title {
                position: relative;
                max-width: 600px;
                margin: auto;
            }
            .left-leaves {
                width: 170px;
                position: absolute;
                left: -15px;
                top: -100px;
            }
            .right-leaves {
                width: 170px;
                position: absolute;
                right: -15px;
                top: -100px;
            }
        }
        .stars-container {
            @include flexCenter(row);
            justify-content: space-around !important;
            margin: auto;
        }
        .family-vector {
            flex: 1;
            max-width: 574px;
        }
        .stars {
            flex: 1;
            text-align: left;
            position: relative;
            margin-top: 12px;
            max-width: 574px;

            .star-content {
                max-width: 500px;
            }
            & :nth-child(1) {
                margin-top: 52px;
            }
            & ::before {
                content: "";
                background-image: url("../assets/Star.svg");
                position: absolute;
                left: -20px;
                background-repeat: no-repeat;
                background-size: 25px 35px;
                width: 25px;
                height: 50px;
            }
            & h2 {
                font-weight: 400;
                margin-top: 30px;
                font-size: 28px;
                padding-left: 15px;
            }
        }
        & h1 {
            font-size: 42px;
            margin-bottom: 30px;
            text-align: center;
        }
        & p {
            text-align: justify;
            @include roboto(20px);
            margin: 0;
            font-weight: 300;
        }
    }

    .about-block-container {
        padding: 0 146px;
        width: 100%;
        @include flexCenter(column);
        margin: auto;
        .meet-the-team {
            font-weight: 400;
            margin-top: 70px;
            margin-bottom: 20px;
        }
        .last {
            margin-bottom: 100px !important;
        }
        .about-block {
            @include flexCenter(row);
            max-width: 1148px;
            height: 500px;
            margin: 25px 0;
            .michael {
                background-image: url("../assets/michael.png");
            }
            .john {
                background-image: url("../assets/john.png");
            }
            .matthew {
                background-image: url("../assets/matt.png");
            }
            .mark {
                background-image: url("../assets/mark.png");
            }
            .wesley {
                background-image: url("../assets/wesley.png");
            }

            .about-image-block {
                background-repeat: no-repeat;
                background-size: cover;
                width: 574px;
                height: 100%;
            }
            .about-text-block {
                padding: 0 53px;
                width: 574px;
                height: 100%;
                background-color: #f6f6f8;
                text-align: left;
                @include flexCenter(column);
                align-items: flex-start;
                justify-content: flex-start;
                padding-top: 50px;
                & h3 {
                    margin-bottom: 15px;
                }
                & p {
                    text-align: justify;
                    @include roboto(20px);
                    font-weight: 300;
                    margin-bottom: 50px;
                }
                a {
                    font-size: 24px;
                    color: #fb825a;
                    font-weight: 600;
                    &::after {
                        content: url("../assets/arrow.svg");
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1076px) {
    .stars-container {
        flex-wrap: wrap;
    }
    .left-leaves {
        display: none !important;
    }
    .right-leaves {
        display: none !important;
    }
    .family-vector {
        // display: none !important;
        width: 200px !important;
        margin-bottom: 40px;
    }
    h1 {
        font-size: 35px !important;
    }
    h2 {
        font-size: 24px !important;
        margin: 10px 0 !important;
    }
    .about-container {
        width: 100%;
        padding: 30px 25px 0 !important;
        .about-header {
            margin-top: 0px !important;
            flex-direction: column;
            .about-us {
                padding: 0;
            }

            .stars {
                min-width: 250px;
                margin: 0 0 0 15px !important;
                order: 1;
                & ::before {
                    left: -13px !important;
                    background-size: 20px 28px !important;
                    width: 20px !important;
                    height: 30px !important;
                }
            }
            & :nth-child(1) {
                margin-top: 0px !important;
            }
        }
    }
    .about-block-container {
        padding: 0 !important;
        width: 100% !important;
        max-width: 600px;
    }
    .about-block {
        flex-direction: column !important;
        height: 100% !important;
        width: 100% !important;
        padding: 0;
        .about-image-block {
            order: 0;
            max-width: 600px;
            width: 100% !important;
            min-height: 500px;
        }
        .about-text-block {
            order: 1;
            margin: auto;
            text-align: left;
            margin: 0 !important;
            max-width: 600px;
            width: 100% !important;
            padding: 0 20px !important;
            h3,
            p,
            a {
                margin: 5px 0 !important;
                padding: 0 !important;
            }
            h3 {
                margin-top: 20px !important;
            }
            p {
                text-align: left !important;
                padding-bottom: 35px !important;
            }
        }
    }
}

@media only screen and (max-width: 620px) {
    .about-image-block {
        min-height: 400px !important;
    }
}
@media only screen and (max-width: 500px) {
    .about-image-block {
        min-height: 300px !important;
    }
}
@media only screen and (max-width: 400px) {
    .about-image-block {
        min-height: 250px !important;
    }
}
